import React from 'react'

const NoDataFound = () => {
    return (
        <div className="no-records">
            <h5>No listings found. Please select another category.</h5>
        </div>
    )
}

export default NoDataFound
