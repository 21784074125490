import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const DateTimePicker = ({
    handleLimboField,
    placeholder,
    field,
    date,
    setDate,
    handleOnChage,
}: any) => {
    const {
        limboFieldType,
        limboFieldId,
        displayOrder,
        fieldDataType,
        limboFieldName,
    } = field
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 7);
    const [startDate, setStartDate] = useState(currentDate)

    const getDatePickerComponent = (limboFieldName: any) => {
        if (limboFieldName === 'Check-out Date') {
            return (
                <DatePicker
                    // selected={
                    //     date.endDate <= date.startDate ||
                    //     date.endDate != date.startDate
                    //         ? date.startDate
                    //         : date.endDate
                    // }
                    selected={date.endDate}
                    onChange={(date: Date) => {
                        setDate((prev: any) => {
                            return { ...prev, endDate: date }
                        })
                        handleLimboField(
                            limboFieldId,
                            limboFieldName,
                            null,
                            date,
                            fieldDataType,
                            displayOrder
                        )
                    }}
                    placeholderText={placeholder}
                    minDate={date.startDate}
                    showDisabledMonthNavigation
                />
            )
        } else if (limboFieldName === 'Check-in Date') {
            return (
                <DatePicker
                    selected={date.startDate}
                    onChange={(date: Date) => {
                        setDate((prev: any) => {
                            return { ...prev, startDate: date }
                        })

                        handleLimboField(
                            limboFieldId,
                            limboFieldName,
                            null,
                            date,
                            limboFieldType,
                            displayOrder
                        )
                    }}
                    placeholderText={placeholder}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                />
            )
        } else {
            return (
                <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => {
                        setStartDate(date)
                        handleOnChage('endAt', date)
                    }}
                    placeholderText={placeholder}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    showTimeSelect
                />
            )
        }
    }

    return <div>{getDatePickerComponent(limboFieldName)}</div>
}

export default DateTimePicker
